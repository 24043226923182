.faq-for-businesses .card-header {
  background: #fbfbfb;
}

.faq-for-businesses .card-body {
  color: #848484;
  font-size: 15px;
}

.terms-section {
  color: #848484;
}

.terms-section h2 {
  font-size: 18px;
  font-weight: 600;
  color: #646464;
  margin: 1rem 0 0.75rem;
}

.terms-section ul {
  margin: 1.25rem 0 1.25rem 3rem;
}

.terms-section ul li {
  list-style: disc;
  margin-bottom: 1rem;
}

.terms-section ol {
  margin: 1.5rem 0 1.5rem 1rem;
}

.terms-section ol li {
  list-style: decimal;
  margin: 2rem 0;
}

.terms-section ol.alpha {
  margin: 1rem 0 1rem 1rem;
}

.terms-section ol.alpha li {
  list-style: lower-alpha;
  margin: 1rem 0;
}

.terms-section ol.roman {
  margin: 1rem 0 1rem 1rem;
}

.terms-section ol.roman li {
  list-style: lower-roman;
  margin: 1rem 0;
}

.faq-terms-privacy-links {
  color: #848484;
  text-decoration: underline;
}

.faq-terms-privacy-links:hover {
  color: #3d3d3d;
}

/* starts of contact page */
.contact-form-div {
  max-width: 400px;
  margin: auto;
}

.contact-form-div .form-control::placeholder,
.contact-form-div .form-control::-webkit-input-placeholder {
  color: #acacac;
}

.contact-form-div .send-btn {
  width: 100%;
  background: #ff6961;
  color: #fff;
  border: none;
  padding: 7px;
  font-weight: 600;
  border-radius: 5px;
}

.contact-form-div .send-btn:hover {
  background: #ea4c4c;
}

.contact-form-div .send-btn:focus {
  outline: 0;
  box-shadow: none;
}

.react-tel-input .form-control {
  width: 100% !important;
}

.store-div {
  max-width: fit-content;
  margin: auto;
  margin-top: 20px;
  margin-bottom: 25px;
}

.store-icons {
  width: 150px;
  margin: 5px;
  cursor: pointer;
}

@media (max-width: 575px) {
  .store-icons {
    width: 135px;
  }
}
/* ends of contact page */
