.my-account-container .form-control:focus {
    border: 1.5px solid #ff7068;
    outline: 0;
    box-shadow: none;
  }
  
  .my-account-container .edit-save-btn {
    color: #ff7068;
    font-size: 14px;
  }
  
  .my-account-container .edit-save-btn:hover {
    cursor: pointer;
  }
  
  .address-table tr {
    border-bottom: 1px solid #ccc;
    padding: 5px;
  }
  
  .address-table tr:hover {
    background: rgb(245, 245, 245);
  }
  
  .address-table tr .fa-trash-alt {
    visibility: hidden;
  }
  
  .address-table tr:hover .fa-trash-alt {
    visibility: visible;
    color: #fa9090;
    cursor: pointer;
  }
  
  .address-table tr:hover .fa-trash-alt:hover {
    color: #f13636;
  }
  
  .address-table tr td:nth-child(1) {
    width: 50px;
    text-align: left;
    padding: 5px 5px 5px 10px;
  }
  
  .address-table tr td:nth-child(2) {
    width: 200px;
    padding: 5px;
  }
  
  .address-table tr td:nth-child(3) {
    width: 25px;
    padding: 5px;
  }

  .edit-card-payment-div {
    font-size: 14px;
  }
  
  .edit-card-payment-div .fa-credit-card {
    font-size: 25px;
    color: #ff7068;
    vertical-align: middle;
    margin-right: 10px;
  }
  
  .edit-card-payment-div .fa-circle {
    font-size: 4px;
    vertical-align: middle;
    margin-right: 1px;
  }
  
  .edit-card-payment-div .default {
    color: aqua;
  }
  
  .edit-card-payment-div .others {
    visibility: hidden;
  }
  
  .edit-card-payment-div .fa-trash-alt {
    visibility: hidden;
  }
  
  .edit-card-payment-div:hover {
    cursor: pointer;
  }
  
  .edit-card-payment-div:hover .others {
    visibility: visible;
    color: #828282;
  }
  
  .edit-card-payment-div:hover .fa-trash-alt {
    visibility: visible;
    color: #fa9090;
  }
  
  .edit-card-payment-div:hover .fa-trash-alt:hover {
    color: #f13636;
  }
  
  .edit-card-payment-div:hover .others:hover {
    cursor: pointer;
    color: #28a745;
  }
  

  /* map modal */
.map-close {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(0, 0, 0);
  z-index: 1;
  background: #fff !important;
  padding: 1px 8px 4px 10px !important;
  border-radius: 50%;
  opacity: 1;
  font-weight: 400;
  font-size: 30px;
}

.map-close:focus {
  outline: 0;
}
