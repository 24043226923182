.past-orders-div .rest-col {
    padding: 25px 0;
  }
  
  .past-orders-div .receipt-col {
    padding: 20px 0 20px 35px;
  }
  
  .past-orders-div .rest-col .img-div {
    position: relative;
    height: fit-content;
    overflow: hidden;
  }
  
  .past-orders-div .rest-col .img-div img {
    width: 100%;
    height: 130px;
    object-fit: cover;
    filter: brightness(50%);
  }
  
  .past-orders-div .rest-col .img-div .open {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: rgb(190, 190, 190);
  }
  
  .past-orders-div .rest-col .img-div .open:hover {
    text-decoration: none;
    color: #fff;
  }
  
  .receipt-modal .header {
    height: 200px;
    background: #ff8b8b;
    padding: 25px 20px;
  }
  
  .receipt-modal .receipt-date {
    font-size: 14px;
    margin-bottom: 0;
    margin-top: 60px;
  }
  
  .receipt-modal .rest-name {
    margin-bottom: 0;
  }
  
  @media (max-width: 575px) {
    .past-orders-div .rest-col {
      padding: 25px 0 0 0;
    }
  
    .past-orders-div .receipt-col {
      padding: 20px 0 5px 0;
    }
  }
  
  @media (min-width: 576px) and (max-width: 768px) {
    .past-orders-div .rest-col {
      padding: 20px 0 20px 0;
    }
  
    .past-orders-div .receipt-col {
      padding: 20px 0 0 20px;
    }
  
    .past-orders-div .rest-col .img-div img {
      height: 90px;
    }
  }
  