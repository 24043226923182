.password-reset-div {
    width: 400px;
    margin: auto;
    margin-top: 100px;
    margin-bottom: 100px;
    
}

.submit-btn {
    width: 100%;
    background: #ff6961;
    border-radius: 3px;
    padding: 7px 15px;
    font-size: 16px;
    font-weight: 700;
    text-align: center;
    cursor: pointer;
    color: #fff;
    border: 0;
    margin-top: 15px;
  }
  
  .login-btn:hover {
    background: #ec3f36;
  }
  